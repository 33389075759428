import React from "react";
import { Routes, Route } from "react-router-dom";

import {
  Header,
  Presentation,
  Definition,
  About,
  Features,
  PrivacyPolicy,
  Vyyer,
} from "./components/index";
import Footer from "./components/bestow/Footer/Footer";
import GlobalStyles from "./components/styles/Globals";
import ComingSoonSection from "./components/bestow/ComingSoonSection/ComingSoonSection";
import TextVideoComponent from "./components/bestow/TextVideoComponent/TextVideoComponent";

import { data } from "./data/data";
import useRedirect from "./components/bestow/utils/useRedirect";

function App() {
  // Add the event listener to get if the elements
  // are in the view port and add animation to them
  (function () {
    let elementsToAnimate;
    let windowHeight;

    function init() {
      elementsToAnimate = document.querySelectorAll(".to-animate");
      windowHeight = window.innerHeight;
      console.log(elementsToAnimate);
    }

    function checkPosition() {
      for (let i = 0; i < elementsToAnimate.length; i++) {
        let element = elementsToAnimate[i];
        let positionFromTop = elementsToAnimate[i].getBoundingClientRect().top;

        if (positionFromTop - windowHeight <= 0) {
          if (!element.classList.contains("animated")) {
            element.classList.add("animated");
            console.log(`The element number ${i} is added`);
          }
        }
      }
    }

    window.addEventListener("DOMContentLoaded", () => {
      window.addEventListener("scroll", checkPosition);

      window.addEventListener("resize", init);

      init();
      checkPosition();
    });
  })();

  return (
    <>
      <GlobalStyles />
      <Routes>
        <Route path="/" element={<Main />} />
        <Route path="policy" element={<PrivacyPolicy />} />
        <Route path="vyyer" element={<Vyyer />} />
      </Routes>
    </>
  );
}

function Main() {
  useRedirect();
  return (
    <div style={{ width: "100%" }}>
      <GlobalStyles />
      <Header />
      <Presentation />
      <Definition />
      <About />
      <Features />
      <TextVideoComponent data={data[0]} />
      <TextVideoComponent data={data[1]} />
      <TextVideoComponent data={data[2]} />
      <ComingSoonSection />
      <Footer />
    </div>
  );
}

export default App;
