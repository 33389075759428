import React from "react";
import { StyledWrapper } from "../styles/helpers.styled";

function Vyyer() {
    return (
        <>
            <StyledWrapper style={{ height: "max-content" }}>
                <Main />
            </StyledWrapper>
        </>
    );
}

function Main() {
    return (
        <>
            <h1>Empty page</h1>
        </>
    );
}

export default Vyyer;
