import { useLayoutEffect } from "react";

export default function useRedirect() {

  var hasData = window.location.search.replace('?data=','');

  var androidIntentUrl =
    "intent://bestowpayments.com/" +
    window.location.search +
    `#Intent;scheme=http;package=com.bestowpayments.app;S.browser_fallback_url=https%3A%2F%2Fplay.google.com%2Fstore%2Fapps%2Fdetails?id=com.bestowpayments.app&referrer=${hasData}` +
    ";end";
  
  var isMobile = {
    Windows: function () {
      return /IEMobile|Windows Phone|Lumia/i.test(navigator.userAgent);
    },
    Android: function () {
      return /Android/i.test(navigator.userAgent);
    },
    iPhone: function () {
      return /iPhone/i.test(navigator.userAgent);
    },
  };
const storeLink = 'https://play.google.com/store/apps/details?id=com.bestowpayments.app&launch=true&lnk=vUcRLz'

  useLayoutEffect(() => {
    if (!isMobile.Windows() && hasData) {
      if (isMobile.Android()) {
        // if(window.location.href.indexOf('https://bestowmobile.com?lnk=vUcRLz') !== -1) { 
        //   window.location = storeLink;
        // }
        window.location = androidIntentUrl;
      }
      if (isMobile.iPhone()) {
        // window.location = appleStoreUrl;
      }
    }
  }, []);
}
